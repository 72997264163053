<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <div class="drawer-header d-flex align-center">
      <span v-t="'addNewProject'" class="font-weight-semibold text-base text--primary" />
      <v-spacer></v-spacer>
      <v-btn icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card-text>
        <v-subheader v-t="'basicData'" />
        <v-text-field
          v-model="projectData.name"
          outlined
          dense
          :rules="[validators.required]"
          :label="$t('name')"
          :placeholder="$t('name')"
          hide-details="auto"
          maxlength="150"
          class="mb-3"
        ></v-text-field>

        <v-textarea
          v-model="projectData.description"
          outlined
          auto-grow
          :label="$t('description')"
          :placeholder="$t('description')"
          rows="4"
          maxlength="1000"
          hide-details="auto"
          class="mb-3"
        />
        <v-autocomplete
          v-model="projectData.status"
          :label="$t('status', { postfix: '' })"
          :placeholder="$t('status', { postfix: '' })"
          :items="statusChoices"
          outlined
          dense
          clearable
          hide-details
          class="mb-3"
        ></v-autocomplete>
        <v-combobox
          v-model="projectData.tags"
          :items="tagsChoices"
          class="mb-3"
          :label="$t('tag')"
          :placeholder="$t('tag')"
          item-text="tag"
          item-value="id"
          multiple
          outlined
          dense
          hide-details
        >
          <template v-slot:selection="{ attrs, item, parent, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              small
              label
              color="info"
              outlined
              class="font-weight-semibold disable-min-height--chip mt-1"
            >
              <span class="pr-3">{{ item === Object(item) ? item.tag : item }}</span>
              <v-icon x-small @click="parent.selectItem(item)"> $delete </v-icon>
            </v-chip>
          </template>
        </v-combobox>
        <v-autocomplete
          v-model="projectData.client"
          :label="$t('client')"
          :placeholder="$t('client')"
          :items="clientChoices"
          outlined
          dense
          clearable
          hide-details
          class="mb-3"
          :rules="[validators.required]"
        ></v-autocomplete>

        <v-expansion-panels class="mb-3" multiple>
          <v-expansion-panel v-for="(expansionPanel, index) in expansionPanels" :key="index">
            <v-expansion-panel-header> {{ $t(expansionPanel.title) }} </v-expansion-panel-header>
            <v-expansion-panel-content v-if="index === 0">
              <v-row>
                <v-col v-for="(file, fileIndex) in projectData.files" :key="`Project--File-${fileIndex}`" cols="12">
                  <v-card outlined>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <file-pond
                            v-model="file.file"
                            :label-idle="$t('filePondIdle')"
                            :allow-multiple="false"
                            accepted-file-types="application/pdf, image/png, image/jpeg"
                            max-file-size="20MB"
                            max-total-file-size="20MB"
                            @addfile="setUploadedFileName(file)"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="file.name"
                            outlined
                            dense
                            :rules="[validators.required]"
                            :label="$t('name')"
                            :placeholder="$t('name')"
                            maxlength="150"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-combobox
                            v-model="file.types"
                            :items="fileTypes"
                            :label="$t('type')"
                            :placeholder="$t('type')"
                            item-text="type"
                            item-value="id"
                            multiple
                            outlined
                            dense
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col cols="10">
                          <v-menu
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="file.validUntil"
                                :label="$t('validUntil', { date: '' })"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="attrs"
                                hide-details="auto"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="file.validUntil"
                              :first-day-of-week="1"
                              :locale="$i18n.locale"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="2" class="d-flex align-center justify-end">
                          <v-tooltip top transition="scroll-y-transition" open-delay="150" color="secondary">
                            <template #activator="{ on, attrs }">
                              <v-btn
                                elevation="0"
                                color="error"
                                icon
                                v-bind="attrs"
                                @click="deleteFileToUpload(projectData.files, fileIndex)"
                                v-on="on"
                              >
                                <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                              </v-btn>
                            </template>
                            <span class="text-xs"> {{ $t('removeFile') }} </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-btn color="primary" block @click="addFileToUploadHandler">{{ $t('addFile') }}</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="index === 1">
              <v-text-field
                v-for="customField in customFields"
                :key="customField.id"
                v-model="customField.field"
                outlined
                dense
                :label="customField.name"
                :placeholder="customField.name"
                hide-details
                maxlength="100"
                class="mb-3"
              ></v-text-field>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row>
          <v-col v-for="(position, index) in projectData.positions" :key="`Position--${index}`" cols="12">
            <v-card outlined class="card--border-primary">
              <v-card-title>
                <v-row>
                  <v-col cols="12" lg="7">
                    <v-text-field
                      v-model="position.name"
                      :label="$t('name')"
                      :placeholder="$t('name')"
                      outlined
                      dense
                      maxlength="150"
                      hide-details="auto"
                      :rules="[validators.required]"
                    />
                  </v-col>
                  <v-col cols="12" lg="5">
                    <v-text-field
                      v-model="position.demand"
                      :label="$t('demand')"
                      :placeholder="$t('demand')"
                      outlined
                      dense
                      type="number"
                      min="0"
                      hide-details="auto"
                      :rules="[validators.minPositiveValue]"
                    >
                      <template #append-outer>
                        <v-tooltip top transition="scroll-y-transition" open-delay="150">
                          <template #activator="{ on, attrs }">
                            <v-btn
                              color="error"
                              x-small
                              v-bind="attrs"
                              @click="deletePosition(index, position, projectData.positions)"
                              v-on="on"
                            >
                              <v-icon class="me-2" size="16">
                                {{ icons.mdiTrashCanOutline }}
                              </v-icon>
                              <span v-t="'delete'" />
                            </v-btn>
                          </template>
                          <span v-t="'delete'" class="text-xs" />
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-autocomplete
                  v-model="position.assignees"
                  :items="employeesChoices"
                  outlined
                  chips
                  clearable
                  deletable-chips
                  multiple
                  hide-details="auto"
                  :label="$t('employee')"
                  :placeholder="$t('employee')"
                  :rules="[validators.nonEmptyValueValidator]"
                  class="mb-3"
                />
                <upgrade-alert-wrapper :form="false" class="mb-3">
                  <template #blocked-features>
                    <v-menu
                      close-on-content-click
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                    >
                      <template v-slot:activator="activator">
                        <v-text-field
                          v-model="position.dateStart"
                          :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          :label="$t('assignmentDateStart')"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="activator.attrs"
                          hide-details="auto"
                          :rules="[validators.dateRangeValidator(position.dateStart, position.dateEnd)]"
                          class="mb-3"
                          v-on="activator.on"
                        />
                      </template>
                      <v-date-picker v-model="position.dateStart" :first-day-of-week="1" :locale="$i18n.locale" />
                    </v-menu>
                    <v-menu
                      :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="activator">
                        <v-text-field
                          v-model="position.dateEnd"
                          :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          :label="$t('assignmentDateEnd')"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="activator.attrs"
                          hide-details="auto"
                          :rules="[validators.dateRangeValidator(position.dateStart, position.dateEnd)]"
                          class="mb-3"
                          v-on="activator.on"
                        />
                      </template>
                      <v-date-picker v-model="position.dateEnd" :first-day-of-week="1" :locale="$i18n.locale" />
                    </v-menu>
                  </template>
                </upgrade-alert-wrapper>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-btn v-t="'addPosition'" color="primary" block @click="addPosition(projectData.positions)" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="overwritten-drawer-actions">
          <v-col cols="12" md="6">
            <v-btn v-t="'add'" color="primary" class="me-3" type="submit" :disabled="!valid" block :loading="loading" />
          </v-col>
          <v-col cols="12" md="6">
            <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="resetProjectData" />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { required, minPositiveValue, dateRangeValidator, nonEmptyValueValidator } from '@core/utils/validation'

import { mdiClose, mdiTrashCanOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import _ from 'lodash'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import fileUploadUtils from '@/utils/fileUploadUtils'
import projectUtils from '@/utils/projectUtils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import UpgradeAlertWrapper from '@/components/UpgradeAlertWrapper.vue'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileEncode, FilePondPluginFileValidateSize)

export default {
  components: {
    FilePond,
    UpgradeAlertWrapper,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      addPosition,
      deletePosition,
      fetchStatusChoices,
      fetchClientChoices,
      fetchFileTypes,
      fetchTagChoices,
      getEmployeesChoices,

      statusChoices,
      clientChoices,
      employeesChoices,
      fileTypes,
      tagsChoices,
    } = projectUtils()

    const { setUploadedFileName, parseFilesToCreate, addFileToUpload, deleteFileToUpload } = fileUploadUtils()

    const expansionPanels = [
      {
        title: 'files',
      },
      {
        title: 'customFields',
      },
    ]

    const customFields = ref([])

    const blankProjectData = {
      name: '',
      description: '',
      status: null,
      company: null,
      positions: [],
      files: [],
      tags: [],
    }

    const { createProject, getProjectCustomFields } = useActions('staff', ['createProject', 'getProjectCustomFields'])

    const loading = ref(false)

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const projectData = ref(_.cloneDeep(blankProjectData))

    const resetProjectData = () => {
      projectData.value = _.cloneDeep(blankProjectData)
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    const onSubmit = async () => {
      if (valid.value) {
        loading.value = true
        projectData.value.files = parseFilesToCreate(projectData.value.files)
        await createProject({
          ...projectData.value,
          tags: projectData.value.tags.map((tag) => {
            return _.isObject(tag) ? tag : { tag }
          }),
          customFieldsValues: customFields.value.map((field) => {
            return { customField: field.id, field: field.field }
          }),
          positions: projectData.value.positions.map((position) => ({
            name: position.name,
            demand: position.demand,
            assignees: position.assignees.map((assignee) => ({
              employee: assignee,
              dateStart: position.dateStart,
              dateEnd: position.dateEnd,
            })),
          })),
        })
        loading.value = false
        resetProjectData()
        emit('refetch-data')
      } else {
        validate()
      }
    }

    const addFileToUploadHandler = () => {
      addFileToUpload(projectData.value.files, { validUntil: true, types: true })
    }

    watch(
      () => props.isAddNewUserSidebarActive,
      async (newVal) => {
        if (newVal) {
          await fetchStatusChoices()
          await fetchClientChoices()
          await fetchFileTypes()
          await fetchTagChoices()
          await getEmployeesChoices()
          customFields.value = await getProjectCustomFields()
        }
      },
    )

    return {
      addPosition,
      deletePosition,
      resetProjectData,
      onSubmit,

      expansionPanels,
      form,
      loading,
      projectData,
      valid,
      employeesChoices,
      statusChoices,
      clientChoices,
      customFields,

      setUploadedFileName,
      addFileToUploadHandler,
      deleteFileToUpload,
      fileTypes,
      tagsChoices,

      // validation
      validators: { required, minPositiveValue, dateRangeValidator, nonEmptyValueValidator },
      icons: {
        mdiClose,
        mdiTrashCanOutline,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
