import { mdiAccountOutline, mdiCogOutline, mdiDnsOutline, mdiAbacus } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'

export default function useProjectList() {
  const projects = ref([])
  const choices = ref({})

  const statusesFilter = ref([])
  const clientFilter = ref([])
  const tagsFilter = ref([])

  const tableColumns = [
    { text: 'name', value: 'name', class: 'ps-7 text-uppercase' },
    { text: 'status', value: 'status', class: 'text-uppercase' },
    { text: 'tags', value: 'tags', class: 'text-uppercase', sortable: false },
    { text: 'takenAssignees', value: 'takenAssignees', align: 'center', class: 'text-uppercase' },
    { text: 'demand', value: 'demandCounter', align: 'center', class: 'text-uppercase' },
    { text: 'workload', value: 'workload', align: 'center', class: 'text-uppercase' },
    { text: 'positionAmount', value: 'positionAmount', align: 'center', class: 'text-uppercase' },
    { text: 'clientRegistrationName', value: 'clientRegistrationName', class: 'text-uppercase' },
    {
      text: 'actions',
      value: 'actions',
      align: 'center',
      sortable: false,
      class: 'text-uppercase',
    },
  ]

  const searchQuery = ref('')
  const totalProjectListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortDesc: [true],
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  })
  const userTotalLocal = ref([])
  const overview = ref([])

  const { getProjects, getProjectsFilterChoices, getProjectsOverview } = useActions('staff', [
    'getProjects',
    'getProjectsFilterChoices',
    'getProjectsOverview',
  ])

  const fetchProjectsOverview = async () => {
    overview.value = await getProjectsOverview()
  }

  // fetch data
  const fetchProjects = async () => {
    loading.value = true
    const { data, success } = await getProjects({
      status: statusesFilter.value,
      client: clientFilter.value,
      tags: tagsFilter.value,
      search: searchQuery.value,
      page: options.value.page,
      pageSize: options.value.itemsPerPage,
      ordering: options.value.sortBy.map((el, index) => {
        const parsedEl = el
          .replaceAll('.', '__')
          .replace('takenAssignees', 'taken_assignees')
          .replace('positionAmount', 'position_amount')
          .replace('demandCounter', 'demand_counter')
          .replace('clientRegistrationName', 'client__registration_name')
          .replace('clientName', 'client__name')

        return `${options.value.sortDesc[index] ? '-' : ''}${parsedEl}`
      }),
    })
    if (success) {
      if (Object.hasOwnProperty.bind(data)('results') && Object.hasOwnProperty.bind(data)('count')) {
        projects.value = data.results
        totalProjectListTable.value = data.count
      } else {
        projects.value = data
        totalProjectListTable.value = data.length
      }
      loading.value = false
    }
    choices.value = await getProjectsFilterChoices({
      status: statusesFilter.value,
      client: clientFilter.value,
      tags: tagsFilter.value,
      search: searchQuery.value,
    })
  }

  const timer = ref(null)
  const delaySearchHandler = async () => {
    clearTimeout(timer.value)
    timer.value = setTimeout(async () => {
      await fetchProjects()
    }, 1000)
  }

  watch([statusesFilter, clientFilter, tagsFilter, options], async () => {
    await fetchProjects()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveProjectTotalIcon = (title) => {
    if (title === 'total') return { icon: mdiCogOutline, color: 'secondary' }
    if (title === 'position amount') return { icon: mdiDnsOutline, color: 'primary' }
    if (title === 'taken assignees') return { icon: mdiAccountOutline, color: 'success' }
    if (title === 'demand') return { icon: mdiAbacus, color: 'success' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    fetchProjectsOverview,
    fetchProjects,
    resolveProjectTotalIcon,
    delaySearchHandler,

    tableColumns,
    searchQuery,
    totalProjectListTable,
    loading,
    options,
    userTotalLocal,
    overview,
    projects,
    choices,
    statusesFilter,
    clientFilter,
    tagsFilter,
  }
}
